const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/InvoiceAdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";

import { useTranslation, AdminLayout } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import {
  createObject,
  deleteObject,
  updateObject,
} from "@opendash/plugin-parse-admin";
import {
  Button,
  Divider,
  List,
  message,
  Modal,
  Space,

  Tooltip,
} from "antd";
import { useParseQuery } from "parse-hooks";
import { Invoice } from "../types";
import { Icon } from "@opendash/icons";
import { useNavigate } from "react-router-dom";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ParseObjectOrderButtons } from "./ParseObjectOrderButtons";

export const InvoiceAdminRoute = React.memo(function InvoiceAdminRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const currentTenant = _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant")]);

  const [showDeleted, setShowDeleted] = React.useState(false);

  const query = React.useMemo(() => {
    const query = new Parse.Query(Invoice)
      .equalTo("tenant", currentTenant)
      .descending("order")
      .limit(1000000);

    if (!showDeleted) {
      query.equalTo("deleted", false);
    }

    return query;
  }, [_optionalChain([currentTenant, 'optionalAccess', _6 => _6.id]), showDeleted]);

  const invoices = useParseQuery(query);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      , React.createElement(AdminToolbar, {
        title: t("app:admin.invoices.title"),
        description: t("app:admin.invoices.description"),
        padding: false,
        actions: [
          React.createElement(Button, {
            key: "new",
            type: "primary",
            icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} ),
            children: t("app:admin.invoices.action_new"),
            onClick: () => {
              createObject("APP_Invoice").then((objectId) => {
                if (objectId) {
                  navigate(`/admin/rechnungserklaerer/invoice/${objectId}`);
                }
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      )

      , React.createElement(List, {
        loading: invoices.loading,
        dataSource: invoices.result,
        renderItem: (invoice) => {
          return (
            React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
              , React.createElement(React.Fragment, null
                , React.createElement(List.Item.Meta, {
                  title: invoice.get("title"),
                  description: invoice.get("description"),
                  avatar: 
                    React.createElement(Tooltip, {
                      overlay: 
                        invoice.get("deleted")
                          ? t(
                              "app:admin.invoices.visibillity_change_tooltip_deleted"
                            )
                          : invoice.get("draft")
                          ? t(
                              "app:admin.invoices.visibillity_change_tooltip_draft"
                            )
                          : t(
                              "app:admin.invoices.visibillity_change_tooltip_nodraft"
                            )
                      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}

                      , React.createElement(Icon, {
                        icon: 
                          invoice.get("deleted")
                            ? "fa:trash"
                            : invoice.get("draft")
                            ? "fa:eye-slash"
                            : "fa:eye"
                        ,
                        style: {
                          fontSize: 20,
                          cursor: "pointer",
                          color:
                            invoice.get("deleted") || invoice.get("draft")
                              ? "red"
                              : undefined,
                        },
                        onClick: () => {
                          if (invoice.get("deleted")) {
                          } else if (invoice.get("draft")) {
                            invoice.set("draft", false);

                            invoice
                              .save()
                              .then(() => {
                                message.success(
                                  t(
                                    "app:admin.invoices.visibillity_change_success"
                                  )
                                );
                                return invoices.reload();
                              })
                              .catch(() => {
                                message.error(
                                  t(
                                    "app:admin.invoices.visibillity_change_error"
                                  )
                                );
                              });
                          } else {
                            invoice.set("draft", true);
                            invoice
                              .save()
                              .then(() => {
                                message.success(
                                  t(
                                    "app:admin.invoices.visibillity_change_success"
                                  )
                                );
                                return invoices.reload();
                              })
                              .catch(() => {
                                message.error(
                                  t(
                                    "app:admin.invoices.visibillity_change_error"
                                  )
                                );
                              });
                          }
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
                      )
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
                )

                , React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
                  , !showDeleted && (
                    React.createElement(ParseObjectOrderButtons, {
                      objects: invoices.result,
                      current: invoice,
                      onChange: () => {
                        invoices.reload();
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
                    )
                  )

                  , React.createElement('span', { style: { display: "inline-block", width: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}} )

                  , !showDeleted && (
                    React.createElement(Button, {
                      key: "edit",
                      type: "primary",
                      icon: React.createElement(Icon, { icon: "fa:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}} ),
                      children: t("app:admin.invoices.action_edit"),
                      onClick: () => {
                        updateObject(invoice.className, invoice.id).then(
                          (saved) => {
                            if (saved) {
                              invoices.reload();
                            }
                          }
                        );
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
                    )
                  )

                  , !showDeleted && (
                    React.createElement(Button, {
                      key: "edit-pages",
                      type: "primary",
                      icon: React.createElement(Icon, { icon: "fa:file-invoice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}} ),
                      children: t("app:admin.invoices.action_edit_pages"),
                      onClick: () => {
                        navigate(
                          `/admin/rechnungserklaerer/invoice/${invoice.id}`
                        );
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}
                    )
                  )

                  , invoice.get("deleted") && (
                    React.createElement(Button, {
                      key: "delete-restore",
                      type: "default",
                      icon: React.createElement(Icon, { icon: "fa:trash-undo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 213}} ),
                      children: t("app:admin.invoices.action_restore"),
                      onClick: () => {
                        invoice.set("deleted", false);
                        invoice.set("draft", true);
                        invoice.set("order", 100);

                        invoice.save().then(
                          () => {
                            message.success(
                              t("app:admin.invoices.restore_success")
                            );

                            invoices.reload();
                          },
                          (error) => {
                            message.error(
                              t("app:admin.invoices.restore_error")
                            );
                          }
                        );
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 210}}
                    )
                  )

                  , invoice.get("deleted") && (
                    React.createElement(Button, {
                      key: "delete-final",
                      type: "default",
                      danger: true,
                      icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 243}} ),
                      children: t("app:admin.invoices.action_delete_final"),
                      onClick: () => {
                        deleteObject(invoice.className, invoice.id).then(() => {
                          invoices.reload();
                        });
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 239}}
                    )
                  )

                  , !invoice.get("deleted") && (
                    React.createElement(Button, {
                      key: "delete",
                      type: "default",
                      danger: true,
                      icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}} ),
                      children: t("app:admin.invoices.action_delete"),
                      onClick: () => {
                        Modal.confirm({
                          title: t("app:admin.invoices.delete_confirm_title"),
                          content: t(
                            "app:admin.invoices.delete_confirm_message"
                          ),
                          okText: t("app:admin.invoices.delete_confirm_ok"),
                          okType: "danger",
                          cancelText: t(
                            "app:admin.invoices.delete_confirm_cancel"
                          ),
                          onOk() {
                            invoice.set("deleted", true);
                            invoice.set("order", 0);

                            invoice.save().then(
                              () => {
                                message.success(
                                  t("app:admin.invoices.delete_success")
                                );

                                invoices.reload();
                              },
                              (error) => {
                                message.error(
                                  t("app:admin.invoices.delete_error")
                                );
                              }
                            );
                          },
                        });
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}
                    )
                  )
                )
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      )
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 300}} )

      , React.createElement(Checkbox, {
        checked: showDeleted,
        onChange: (e) => {
          setShowDeleted(e.target.checked);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 302}}

        , t("app:admin.invoices.action_show_deleted")
      )
    )
  );
});
