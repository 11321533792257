const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/VideoAdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";
import { DEFAULT_TENANT_ID } from "../const";
import { useTranslation, AdminLayout } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import {
  createObject,
  deleteObject,
  updateObject,
} from "@opendash/plugin-parse-admin";
import { Button, List, Space, Tag } from "antd";
import { useParseQuery } from "parse-hooks";
import { TenantConfig, Video } from "../types";
import { Icon } from "@opendash/icons";
import { ParseObjectOrderButtons } from "./ParseObjectOrderButtons";

export const VideoAdminRoute = React.memo(function VideoAdminRoute() {
  const t = useTranslation();
  const [getVideoQuery, setGetVideoQuery] = React.useState([]);
  const [update, setUpdate] = React.useState(false);

  const currentTenant = _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant")]);

  React.useEffect(() => {
    async function fetchDate() {
      if (!currentTenant.get("tenantConfig").get("hideVideoIds")) {
        const tenantConfig = await new Parse.Query(TenantConfig)
          .equalTo("tenant", currentTenant)
          .first();
        setGetVideoQuery(_optionalChain([tenantConfig, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("hideVideoIds")]));
      } else {
        setGetVideoQuery(currentTenant.get("tenantConfig").get("hideVideoIds"));
      }
    }
    fetchDate();
  }, []);

  let tenant = new Parse.Object("OD3_Tenant");
  tenant.id = DEFAULT_TENANT_ID;

  const query = React.useMemo(
    () =>
      DEFAULT_TENANT_ID == currentTenant
        ? new Parse.Query(Video)
            .equalTo("tenant", currentTenant)
            .descending("order")
            .limit(1000000)
        : Parse.Query.or(
            new Parse.Query(Video)
              .equalTo("tenant", tenant)
              .descending("order")
              .limit(1000000),
            new Parse.Query(Video)
              .equalTo("tenant", currentTenant)
              .descending("order")
              .limit(1000000)
          ),
    [_optionalChain([currentTenant, 'optionalAccess', _8 => _8.id])]
  );

  const changeHideVideoIds = async (id, isHidden) => {
    const hideVideoIds = getVideoQuery || [];
    if (isHidden) {
      hideVideoIds.push(id);
    } else {
      hideVideoIds.splice(hideVideoIds.indexOf(id), 1);
    }
    currentTenant.get("tenantConfig").set("hideVideoIds", hideVideoIds);
    await currentTenant.get("tenantConfig").save();
    setGetVideoQuery(hideVideoIds);
    setUpdate(!update);
  };

  const entries = useParseQuery(query);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
      , React.createElement(AdminToolbar, {
        title: t("app:admin.video.title"),
        description: t("app:admin.video.description"),
        padding: false,
        actions: [
          React.createElement(Button, {
            key: "new",
            type: "primary",
            icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} ),
            children: t("app:admin.video.action_new"),
            onClick: () => {
              createObject("APP_Video").then((objectId) => {
                entries.reload();
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
      )

      , React.createElement(List, {
        loading: entries.loading,
        dataSource: entries.result,
        renderItem: (entry) => {
          return (
            React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
              , React.createElement(React.Fragment, null
                , React.createElement(List.Item.Meta, {
                  title: 
                    React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
                      , entry.get("title"), " "
                      , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}, _optionalChain([entry, 'access', _9 => _9.get, 'call', _10 => _10("language"), 'optionalAccess', _11 => _11.id]))
                    )
                  ,
                  description: entry.get("description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
                )
                , entry.get("tenant").id == currentTenant.id ? (
                  React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
                    , DEFAULT_TENANT_ID == currentTenant.id && (
                      React.createElement(ParseObjectOrderButtons, {
                        objects: entries.result,
                        current: entry,
                        onChange: () => {
                          entries.reload();
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
                      )
                    )

                    , React.createElement('span', { style: { display: "inline-block", width: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}} )

                    , React.createElement(Button, {
                      key: "edit",
                      type: "primary",
                      icon: React.createElement(Icon, { icon: "fa:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}} ),
                      children: t("app:admin.video.action_edit"),
                      onClick: () => {
                        updateObject(entry.className, entry.id).then(
                          (saved) => {
                            if (saved) {
                              entries.reload();
                            }
                          }
                        );
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
                    )

                    , React.createElement(Button, {
                      key: "delete",
                      type: "default",
                      danger: true,
                      icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}} ),
                      children: t("app:admin.video.action_delete"),
                      onClick: () => {
                        deleteObject(entry.className, entry.id).then(
                          (deleted) => {
                            if (deleted) {
                              entries.reload();
                            }
                          }
                        );
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
                    )
                  )
                ) : (
                  React.createElement(React.Fragment, null)
                )
                , DEFAULT_TENANT_ID != currentTenant.id &&
                _optionalChain([getVideoQuery, 'optionalAccess', _12 => _12.includes, 'call', _13 => _13(entry.id)]) ? (
                  React.createElement(Button, {
                    danger: true,
                    icon: React.createElement(Icon, { icon: "fa:eye", __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} ),
                    children: t("app:admin.video.action_hidden"),
                    onClick: () => {
                      changeHideVideoIds(entry.id, false);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
                  )
                ) : (
                  React.createElement(Button, {
                    icon: React.createElement(Icon, { icon: "fa:eye", __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}} ),
                    children: t("app:admin.video.action_shown"),
                    onClick: () => {
                      changeHideVideoIds(entry.id, true);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
                  )
                )
              )
            )
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
      )
    )
  );
});
