const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/ParseObjectOrderButtons.tsx";import React from "react";
import Parse from "parse";

import { Button, Space, Tooltip } from "antd";

import { Icon } from "@opendash/icons";
import { useTranslation } from "@opendash/core";







export const ParseObjectOrderButtons = React.memo(
  function ParseObjectOrderButtons({ objects, current, onChange }) {
    const t = useTranslation();
    return (
      React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(Tooltip, { overlay: t("app:ui.order.tooltip_up"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          , React.createElement(Button, {
            key: "up",
            icon: React.createElement(Icon, { icon: "fa:chevron-up", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} ),
            disabled: objects.indexOf(current) === 0,
            onClick: () => {
              const a = current;
              const aIndex = objects.indexOf(current);
              const bIndex = aIndex - 1;
              const b = objects[bIndex];

              for (const invoice of objects) {
                const index = objects.indexOf(invoice);

                if (invoice === a) {
                  invoice.set("order", 1000 - bIndex);
                } else if (invoice === b) {
                  invoice.set("order", 1000 - aIndex);
                } else {
                  invoice.set("order", 1000 - index);
                }
              }

              Parse.Object.saveAll(objects).then(() => {
                onChange();
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
          )
        )
        , React.createElement(Tooltip, { overlay: t("app:ui.order.tooltip_down"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
          , React.createElement(Button, {
            key: "down",
            icon: React.createElement(Icon, { icon: "fa:chevron-down", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} ),
            disabled: objects.indexOf(current) === objects.length - 1,
            onClick: () => {
              const a = current;
              const aIndex = objects.indexOf(current);
              const bIndex = aIndex + 1;
              const b = objects[bIndex];

              for (const invoice of objects) {
                const index = objects.indexOf(invoice);

                if (invoice === a) {
                  invoice.set("order", 1000 - bIndex);
                } else if (invoice === b) {
                  invoice.set("order", 1000 - aIndex);
                } else {
                  invoice.set("order", 1000 - index);
                }
              }

              Parse.Object.saveAll(objects).then(() => {
                onChange();
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          )
        )
      )
    );
  }
);
