const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/ImportReferenceRoute.tsx";import React from "react";


import { AdminLayout, useTranslation } from "@opendash/core";

export const ImportReferenceRoute = React.memo(function ImportReferenceRoute() {
  const t = useTranslation();

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , React.createElement('p', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, "Coming soon?" )
    )
  );
});
