import Parse from "parse";


Parse.initialize("asew");
Parse.serverURL = "https://parse-asew.apps.openinc.dev/parse";
// Parse.serverURL = "http://localhost:1337/parse";

import { fields as tenantConfigFields } from "./components/TenantConfigRoute";

const tenantConfigFieldsFlat = Object.values(tenantConfigFields).flat();

export const ParseAdminConfig = [
  {
    className: "_User",
    relation: ["username"],
    columns: ["name", "username", "email", "tenant", "tenantAdmin"],
    create_fields: [
      "name",
      "username",
      "email",
      "tenant",
      "tenantAdmin",
      "password",
    ],
    edit_fields: [
      "name",
      "username",
      "email",
      "tenant",
      "tenantAdmin",
      "password",
    ],
    disableACLEditing: true,
  },
  {
    className: "APP_Language",
    relation: ["label"],
    columns: ["label"],
    create_fields: ["label"],
    edit_fields: ["label"],
    disableACLEditing: true,
  },
  {
    className: "OD3_Tenant",
    relation: ["label"],
    columns: ["label", "tenantConfig"],
    create_fields: ["label"],
    edit_fields: ["label"],
    disableACLEditing: true,
  },
  {
    className: "APP_TenantConfig",
    relation: [],
    columns: tenantConfigFieldsFlat,
    create_fields: tenantConfigFieldsFlat,
    edit_fields: tenantConfigFieldsFlat,
    disableACLEditing: true,
    fileValidation: {
      logo: {
        image: true,
        mimeTypes: ["image/jpeg", "image/png", "image/svg+xml"],
        maxFileSize: 1024 * 1024 * 2,
      },
      contactImage: {
        image: true,
        mimeTypes: ["image/jpeg", "image/png", "image/svg+xml"],
        maxFileSize: 1024 * 1024 * 2,
      },
      frontpageBackgroundImage: {
        image: true,
        mimeTypes: ["image/jpeg", "image/png", "image/svg+xml"],
        maxFileSize: 1024 * 1024 * 2,
      },
    },
  },
  {
    className: "APP_TenantDomain",
    relation: ["domain"],
    columns: ["tenant", "domain"],
    create_fields: ["tenant", "domain"],
    edit_fields: ["tenant", "domain"],
    disableACLEditing: true,
  },
  {
    className: "APP_Invoice",
    relation: ["title"],
    columns: ["title"],
    create_fields: ["title", "icon", "description"],
    edit_fields: ["title", "icon", "description"],
    disableACLEditing: true,
  },
  {
    className: "APP_InvoicePage",
    relation: ["title"],
    columns: ["title", "description", "image"],
    create_fields: ["title", "description", "image"],
    edit_fields: ["title", "description", "image"],
    disableACLEditing: true,
  },
  {
    className: "APP_GlossaryEntry",
    relation: ["title"],
    columns: ["language", "title", "text"],
    create_fields: ["language", "title", "text"],
    edit_fields: ["language", "title", "text"],
    disableACLEditing: true,
  },
  {
    className: "APP_Video",
    relation: ["title"],
    columns: [
      "language",
      "title",
      "description",
      "draft",
      "video",
      "image",
      "text",
    ],
    create_fields: [
      "language",
      "title",
      "description",
      "draft",
      "image",
      "video",
      "text",
    ],
    edit_fields: [
      "language",
      "title",
      "description",
      "draft",
      "image",
      "video",
      "text",
    ],
    disableACLEditing: true,
    fileValidation: {
      image: {
        image: true,
        mimeTypes: ["image/jpeg"],
        maxFileSize: 1024 * 1024 * 1,
      },
      video: {
        image: true,
        mimeTypes: ["video/mp4"],
        maxFileSize: 1024 * 1024 * 100,
      },
    },
  },
];
