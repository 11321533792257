const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/InvoicePageAnnotation.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";
import styled from "styled-components";

import {
  useElementSize,
  useTranslation,
  useEventListener,
  uuid,
} from "@opendash/core";

import { Button, Divider, List, message, Modal, Select } from "antd";

import { GlossaryEntry, Language, Video } from "../types";
import { useParseQuery } from "parse-hooks";
import {
  copyObject,

  updateObject,
} from "@opendash/plugin-parse-admin";
import { DEFAULT_TENANT_ID } from "../const";

const PageImageContainer = styled.div`
  position: relative;

  user-select: none;
`;

const PageImageAnnotation = styled.button`
  position: absolute;
  /* left, top and background are calculated */
  height: 40px;
  width: 40px;
  line-height: 30px;
  font-size: 16px;

  /* color: white;
  background: #1890ff; */

  display: inline-block;
  text-align: center;
  border-radius: 50%;
  // border-top-left-radius: 4px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
`;

const AnnotationListItem = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  & ${PageImageAnnotation} {
    width: 30px;
    height: 30px;
    line-height: 24px;
    font-size: 14px;
  }
`;

const INVOICE_WIDTH = 2480;
const INVOICE_HEIGHT = 3508;

const SELECT_FILTER = (input, option) =>
  _optionalChain([option, 'optionalAccess', _ => _.label, 'optionalAccess', _2 => _2.toLowerCase, 'call', _3 => _3(), 'access', _4 => _4.indexOf, 'call', _5 => _5(input.toLowerCase())]) >= 0;




















export const InvoicePageAnnotation = React.memo(
  function InvoicePageAnnotation({ invoice, page, onClose, onChange }) {
    const t = useTranslation();

    const [annotations, setAnnotations] = React.useState([]);
    const [reload, setReload] = React.useState(false);

    const currentTenantId = _optionalChain([Parse, 'access', _6 => _6.User, 'access', _7 => _7.current, 'call', _8 => _8(), 'optionalAccess', _9 => _9.get, 'call', _10 => _10("tenant"), 'optionalAccess', _11 => _11.id]);

    /*
    const glossaryQuery = React.useMemo(
      () =>
        new Parse.Query(GlossaryEntry)
          .equalTo("language", new Language({ id: "de" }))
          .containedIn("tenant", [
            new Parse.Object("OD3_Tenant", { id: currentTenantId }),
            new Parse.Object("OD3_Tenant", { id: DEFAULT_TENANT_ID }),
          ])
          .ascending("title")
          .descending("updatedAt")
          .limit(1000000),
      []
    );

    const glossary = useParseQuery(glossaryQuery);
    */

    const currentTenantQuery = React.useMemo(() => {
      return new Parse.Query(GlossaryEntry)
        .equalTo("language", new Language({ id: "de" }))
        .equalTo(
          "tenant",
          new Parse.Object("OD3_Tenant", { id: currentTenantId })
        )
        .ascending("title")
        .limit(1000000);
    }, [currentTenantId, annotations, reload]);

    const [combinedGlossaryEntries, setCombinedGlossaryEntries] =
      React.useState([]);

    const defaultTenantQuery = React.useMemo(() => {
      return new Parse.Query(GlossaryEntry)
        .equalTo("language", new Language({ id: "de" }))
        .equalTo(
          "tenant",
          new Parse.Object("OD3_Tenant", { id: DEFAULT_TENANT_ID })
        )
        .ascending("title")
        .limit(1000000);
    }, [DEFAULT_TENANT_ID, annotations, reload]);

    React.useEffect(() => {
      const fetchEntries = async () => {
        try {
          // 1. Hol die Einträge vom currentTenantId
          const currentTenantEntries = await currentTenantQuery.find();
          const currentTenantKeywords = currentTenantEntries.map((entry) =>
            entry.get("keyword")
          );

          // 2. Hol die Einträge vom DEFAULT_TENANT_ID, deren Keywords nicht in currentTenantKeywords vorkommen
          const defaultTenantEntries = await defaultTenantQuery
            .notContainedIn("keyword", currentTenantKeywords)
            .find();

          // 3. Kombiniere die Ergebnisse: currentTenantEntries zuerst, dann defaultTenantEntries (falls keyword fehlt)
          setCombinedGlossaryEntries([
            ...currentTenantEntries,
            ...defaultTenantEntries,
          ]);
        } catch (error) {
          console.error("Fehler beim Abrufen der Glossar-Einträge:", error);
        }
      };

      fetchEntries();
    }, [currentTenantQuery, defaultTenantQuery, annotations, reload]);

    const glossaryOptions = React.useMemo(() => {
      const resultMap = {};

      for (const entry of combinedGlossaryEntries) {
        const title = entry.get("title");
        const tags = entry.get("tags") || [];
        const value = entry.get("keyword");
        const label = tags.length > 0 ? `${title} (${tags.join(", ")})` : title;
        const tenantId = _optionalChain([entry, 'access', _12 => _12.get, 'call', _13 => _13("tenant"), 'optionalAccess', _14 => _14.id]);

        if (
          (resultMap[value] && tenantId === currentTenantId) ||
          !resultMap[value]
        ) {
          resultMap[value] = { label, value };
        }
      }

      const result = Object.values(resultMap).sort((a, b) => {
        return a.label.localeCompare(b.label, "de");
      });

      return result;
    }, [combinedGlossaryEntries]);

    const videoQuery = React.useMemo(
      () =>
        new Parse.Query(Video)
          .equalTo("language", new Language({ id: "de" }))
          .containedIn("tenant", [
            new Parse.Object("OD3_Tenant", { id: DEFAULT_TENANT_ID }),
            new Parse.Object("OD3_Tenant", { id: currentTenantId }),
          ])
          .ascending("title")
          .limit(1000000),
      []
    );

    const videos = useParseQuery(videoQuery);

    const videoOptions = React.useMemo(() => {
      return videos.result.map((video) => ({
        label: video.get("title"),
        value: video.id,
      }));
    }, [videos.result]);

    const [keywordSelect, setKeywordSelect] = React.useState

();

    const [videoSelect, setVideoSelect] = React.useState();

    const [addAnnoation, setAddAnnotation] = React.useState(
      null
    );

    const [editAnnoation, setEditAnnotation] =
      React.useState(null);

    const showKeywordModal = addAnnoation !== null || editAnnoation !== null;

    React.useEffect(() => {
      if (!showKeywordModal) {
        setKeywordSelect(undefined);
      }
    }, [showKeywordModal]);

    React.useEffect(() => {
      if (_optionalChain([page, 'optionalAccess', _15 => _15.get, 'call', _16 => _16("annotations")])) {
        setAnnotations(page.get("annotations"));
      }
    }, [_optionalChain([page, 'optionalAccess', _17 => _17.id])]);

    const mouseposition = React.useRef([0, 0]);
    const annotationDrag = React.useRef(
      null
    );

    const imageHeight = _optionalChain([page, 'optionalAccess', _18 => _18.get, 'call', _19 => _19("imageHeight")]) || INVOICE_HEIGHT;
    const imageWidth = _optionalChain([page, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("imageWidth")]) || INVOICE_WIDTH;
    const imageContainerRef = React.useRef(null);
    const imageContainerSize = useElementSize(imageContainerRef, 1000);
    const imageContainerScale = imageContainerSize.width / imageWidth;

    const calculatePosition = () => {
      if (!imageContainerRef.current) {
        return [0, 0];
      }

      const [mouseX, mouseY] = mouseposition.current;
      const scale = imageContainerScale;
      const maxX = imageWidth;
      const maxY = imageHeight;

      const viewportOffset = imageContainerRef.current.getBoundingClientRect();

      const offsetTop = viewportOffset.top;
      const offsetLeft = viewportOffset.left;

      let x = (mouseX - offsetLeft - 15) / scale;
      let y = (mouseY - offsetTop - 15) / scale;

      x = Math.round(x / 10) * 10;
      y = Math.round(y / 10) * 10;

      x = Math.round(Math.max(0, Math.min(x, maxX)));
      y = Math.round(Math.max(0, Math.min(y, maxY)));

      return [x, y];
    };

    const imageClickHandler = (
      e
    ) => {
      const [x, y] = calculatePosition();

      setAddAnnotation([x, y]);
    };

    useEventListener("mousemove", (e) => {
      mouseposition.current = [e.clientX, e.clientY];

      if (annotationDrag.current !== null) {
        // drag
        const [x, y] = calculatePosition();

        const left = Math.round(x * imageContainerScale);
        const top = Math.round(y * imageContainerScale);

        const [, element] = annotationDrag.current;

        element.style.top = top + "px";
        element.style.left = left + "px";
      }
    });

    useEventListener("mouseup", (e) => {
      if (annotationDrag.current !== null) {
        // drop

        const [x, y] = calculatePosition();
        const [index] = annotationDrag.current;

        setAnnotations((current) => {
          const next = [...current];

          next[index] = { ...current[index], x, y };

          return sortAnnotation(next);
        });
      }

      annotationDrag.current = null;
    });

    const save = () => {
      if (page) {
        page.set("annotations", annotations);

        page.save().then(
          () => {
            message.success(
              t("app:admin.invoice_page_annotation.save_success")
            );

            onClose();
          },
          () => {
            message.error(t("app:admin.invoice_page_annotation.save_error"));
          }
        );
      }
    };

    return (
      React.createElement(Modal, {
        title: t("app:admin.invoice_page_annotation.title"),
        visible: !!page,
        onCancel: () => {
          Modal.confirm({
            title: t("app:admin.invoice_page_annotation.exit_title"),
            content: t("app:admin.invoice_page_annotation.exit_content"),
            onOk: save,
            okText: t("app:admin.invoice_page_annotation.exit_ok"),
            onCancel: onClose,
            cancelText: t("app:admin.invoice_page_annotation.exit_cancel"),
          });
        },
        width: 1000,
        // footer={false}

        okText: t("opendash:ui.save"),
        onOk: save, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}

        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 361}}, t("app:admin.invoice_page_annotation.usage"))

        , annotations.length > 0 && (
          React.createElement(List, {
            dataSource: annotations,
            rowKey: "key",
            renderItem: (annotation) => (
              React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 368}}
                /* <span>{annotation.index}</span> */

                , React.createElement(AnnotationListItem, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 371}}
                  , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 372}}
                    , React.createElement(PageImageAnnotation, {
                      className: "ant-btn-primary",
                      style: { position: "relative" },
                      children: annotation.index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 373}}
                    )
                  )

                  , React.createElement(Select, {
                    style: { width: "100%", flexGrow: 1 },
                    value: annotation.keyword,
                    onChange: (value) => {
                      setAnnotations((current) => {
                        const index = annotations.indexOf(annotation);
                        const next = [...current];

                        next[index] = { ...current[index], keyword: value };

                        return sortAnnotation(next);
                      });
                    },
                    options: glossaryOptions,
                    showSearch: true,
                    filterOption: SELECT_FILTER, __self: this, __source: {fileName: _jsxFileName, lineNumber: 380}}
                  )

                  , React.createElement(Button, {
                    danger: true,
                    children: t(
                      "app:admin.invoice_page_annotation.keyword_modal_delete"
                    ),
                    onClick: () => {
                      setAnnotations((current) => {
                        const index = annotations.indexOf(annotation);
                        const next = [...current];

                        next.splice(index, 1);

                        return sortAnnotation(next);
                      });

                      setEditAnnotation(null);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 398}}
                  )
                )
              )
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 364}}
          )
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 422}} )

        , page && (
          React.createElement(PageImageContainer, { ref: imageContainerRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 425}}
            , React.createElement('img', {
              src: _optionalChain([page, 'access', _22 => _22.get, 'call', _23 => _23("image"), 'optionalAccess', _24 => _24.url, 'call', _25 => _25()]),
              style: {
                width: imageContainerSize.width,
                height: Math.round(imageHeight * imageContainerScale),
                border: "1px solid #d9d9d9",
              },
              onClick: imageClickHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 426}}
            )

            , annotations.map((annotation, index) => {
              const left = Math.round(annotation.x * imageContainerScale);
              const top = Math.round(annotation.y * imageContainerScale);

              return (
                React.createElement(PageImageAnnotation, {
                  className: "ant-btn-primary",
                  title: _optionalChain([combinedGlossaryEntries
, 'access', _26 => _26.find, 'call', _27 => _27((e) => e.get("keyword") === annotation.keyword)
, 'optionalAccess', _28 => _28.get, 'call', _29 => _29("title")]),
                  key: annotation.key,
                  style: { left, top },
                  onClick: (e) => {
                    if (e.detail === 2) {
                      setEditAnnotation(index);
                      setKeywordSelect(annotation.keyword);
                      setVideoSelect(annotation.videoId);
                    }
                  },
                  onMouseDown: (e) => {
                    // drag start
                    annotationDrag.current = [index, e.target ];
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 441}}

                  , annotation.index
                )
              );
            })
          )
        )

        , React.createElement(Modal, {
          title: t("app:admin.invoice_page_annotation.keyword_modal_title"),
          visible: showKeywordModal,
          onCancel: () => {
            setEditAnnotation(null);
            setAddAnnotation(null);
            setVideoSelect(undefined);
          },
          onOk: () => {
            if (keywordSelect) {
              if (addAnnoation !== null) {
                const [x, y] = addAnnoation;

                const key = uuid();

                setAnnotations((current) => {
                  const next = [
                    ...current,
                    {
                      key,
                      index: 0,
                      x,
                      y,
                      keyword: keywordSelect,
                      videoId: videoSelect,
                    },
                  ];

                  return sortAnnotation(next);
                });
              }

              if (editAnnoation !== null) {
                const index = editAnnoation;

                setAnnotations((current) => {
                  const next = [...current];

                  next[index] = {
                    ...current[index],
                    keyword: keywordSelect,
                    videoId: videoSelect,
                  };

                  return sortAnnotation(next);
                });
              }
            }

            setEditAnnotation(null);
            setAddAnnotation(null);
            setVideoSelect(undefined);
          },
          okText: t("opendash:ui.save"),
          okButtonProps: {
            disabled: !keywordSelect,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 467}}

          , React.createElement(Select, {
            autoFocus: true,
            value: keywordSelect,
            onChange: (value) => {
              setKeywordSelect(value);
            },
            options: glossaryOptions,
            style: { width: "100%" },
            showSearch: true,
            filterOption: SELECT_FILTER, __self: this, __source: {fileName: _jsxFileName, lineNumber: 525}}
          )

          , keywordSelect && (
            React.createElement('div', { style: { paddingTop: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 538}}
              , _optionalChain([combinedGlossaryEntries
, 'access', _30 => _30.find, 'call', _31 => _31((e) => e.get("keyword") === keywordSelect)
, 'optionalAccess', _32 => _32.get, 'call', _33 => _33("text")])

              , React.createElement(Button, {
                type: "link",
                onClick: () => {
                  const entries = combinedGlossaryEntries.filter(
                    (e) => e.get("keyword") === keywordSelect
                  );

                  if (entries.length === 2) {
                    const e = entries.find(
                      (e) => _optionalChain([e, 'access', _34 => _34.get, 'call', _35 => _35("tenant"), 'optionalAccess', _36 => _36.id]) === currentTenantId
                    );

                    if (e) {
                      updateObject(e.className, e.id).then(() => {
                        setReload(!reload);
                      });
                    }
                  }

                  if (entries.length === 1) {
                    const e = entries[0];

                    if (_optionalChain([e, 'access', _37 => _37.get, 'call', _38 => _38("tenant"), 'optionalAccess', _39 => _39.id]) === currentTenantId) {
                      updateObject(e.className, e.id).then(() => {
                        setReload(!reload);
                      });
                    } else {
                      copyObject(
                        e.className,
                        new Parse.Object(e.className, {
                          ...e.toJSON(),
                          id: undefined,
                          objectId: undefined,
                          createdAt: undefined,
                          updatedAt: undefined,
                        })
                      ).then(() => {
                        setReload(!reload);
                      });
                    }
                  }
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 543}}

                , t("opendash:ui.edit")
              )
            )
          )

          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 591}} )

          , React.createElement('p', {
            children: t("app:admin.invoice_page_annotation.video_description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 593}}
          )

          , React.createElement(Select, {
            placeholder: t(
              "app:admin.invoice_page_annotation.video_placeholder"
            ),
            allowClear: true,
            value: videoSelect,
            onChange: (value) => {
              setVideoSelect(value);
            },
            options: videoOptions,
            style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 597}}
          )

          , editAnnoation !== null && (
            React.createElement(React.Fragment, null
              , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 612}} )
              , React.createElement(Button, {
                danger: true,
                children: t(
                  "app:admin.invoice_page_annotation.keyword_modal_delete"
                ),
                onClick: () => {
                  setAnnotations((current) => {
                    const next = [...current];

                    next.splice(editAnnoation, 1);

                    return sortAnnotation(next);
                  });

                  setEditAnnotation(null);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 613}}
              )
            )
          )
        )
      )
    );
  }
);

function sortAnnotation(annoations) {
  const result = [...annoations].sort((a, b) => {
    if (a.y > b.y) {
      return 1;
    }

    if (a.y < b.y) {
      return -1;
    }

    if (a.x > b.x) {
      return 1;
    }

    if (a.x < b.x) {
      return -1;
    }

    return 0;
  });

  // set index

  for (const annoation of result) {
    annoation.index = 0;
  }

  let index = 1;

  for (const annoation of result) {
    annoation.index =
      _optionalChain([result, 'access', _40 => _40.find, 'call', _41 => _41((a) => a.keyword === annoation.keyword), 'optionalAccess', _42 => _42.index]) || index++;
  }

  return result;
}
