const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/InvoicePagePDFImport.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";

import { useTranslation } from "@opendash/core";
import { createObject } from "@opendash/plugin-parse-admin";
import { Button, Modal, Space, Divider, Upload, Pagination } from "antd";

import { InvoicePage } from "../types";

import * as PDFJS from "pdfjs-dist";




// TODO
// PDFJS.GlobalWorkerOptions.workerSrc =
//   "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.js";
PDFJS.GlobalWorkerOptions.workerSrc = "/pdfjs-worker.js";








export const InvoicePagePDFImport = React.memo(
  function InvoicePagePDFImport({ visible, invoice, onClose, onChange }) {
    const t = useTranslation();

    const previewContainerRef = React.useRef(null);

    const [file, setFile] = React.useState(null);
    const [pdf, setPDF] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);

    React.useEffect(() => {
      if (!visible) {
        setFile(null);
      }
    }, [visible]);

    React.useEffect(() => {
      if (file && previewContainerRef.current) {
        const fileReader = new FileReader();

        fileReader.onload = function () {
          //@ts-ignore
          PDFJS.getDocument(new Uint8Array(fileReader.result)).promise.then(
            (pdf) => {
              setPDF(pdf);
            }
          );
        };

        fileReader.readAsArrayBuffer(file);
      } else {
        setPDF(null);
      }
    }, [file]);

    React.useEffect(() => {
      if (pdf && pdf.numPages > 0) {
        setPage(1);
        setPageCount(pdf.numPages);
      } else {
        setPage(0);
        setPageCount(0);
      }
    }, [pdf]);

    React.useEffect(() => {
      if (pdf && page > 0 && previewContainerRef.current) {
        pdf.getPage(page).then((page) => {
          const scale = 3;
          const viewport = page.getViewport({ scale });

          const canvas = previewContainerRef.current ;
          const context = canvas.getContext("2d") ;

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          page.render({
            canvasContext: context,
            viewport: viewport,
          });
        });
      } else if (previewContainerRef.current) {
        previewContainerRef.current.width = 0;
        previewContainerRef.current.height = 0;
      }
    }, [pdf, page]);

    const importPage = React.useCallback(async () => {
      if (!previewContainerRef.current) {
        return;
      }

      const dataUrl = previewContainerRef.current.toDataURL("image/jpeg");

      const image = new Parse.File(
        `pdf-import-page-${page}.jpg`,
        { base64: dataUrl },
        "image/png"
      );

      await image.save();

      const preset = new InvoicePage({
        tenant: _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant")]),
        invoice,
        image,
        title: `#${page}`,
      });

      createObject(preset.className, preset).then((objectId) => {
        onChange(objectId);
      });
    }, [page]);

    return (
      React.createElement(Modal, {
        title: t("app:admin.invoice_page_pdf_import.title"),
        visible: visible,
        onCancel: onClose,
        width: 1000,
        footer: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}

        , React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          , React.createElement(Upload, {
            fileList: [],
            beforeUpload: (file) => {
              setFile(file);

              return false;
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}

            , React.createElement(Button, {
              children: 
                file
                  ? t("app:admin.invoice_page_pdf_import.action_upload_new")
                  : t("app:admin.invoice_page_pdf_import.action_upload")
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
            )
          )

          , React.createElement(Divider, { type: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}} )

          , React.createElement(Button, {
            type: "primary",
            disabled: !pdf,
            onClick: importPage,
            children: t("app:admin.invoice_page_pdf_import.action_import"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
          )

          , React.createElement(Divider, { type: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}} )

          , pageCount > 0 && (
            React.createElement(Pagination, {
              current: page,
              pageSize: 1,
              total: pageCount,
              showSizeChanger: false,
              onChange: (page) => {
                setPage(page);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
            )
          )
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 173}} )

        , !file && React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}, t("app:admin.invoice_page_pdf_import.no_file_hint"))

        , React.createElement('div', { style: { width: "100%", maxHeight: "50vh", overflowY: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
          , React.createElement('canvas', {
            ref: previewContainerRef,
            style: { width: "100%" },
            width: 0,
            height: 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
          )
        )
      )
    );
  }
);
