const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/AdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import Parse from "parse";
import React from "react";

import { AdminLayout, useNavigation, useTranslation } from "@opendash/core";

import { useNavigate } from "react-router";

import {
  Container,
  ContainerInner,
  Element,
} from "@opendash/core/dist/features/core/components/OpenDashFrontpage.layout";
import { useParseQuery } from "parse-hooks";
import { TenantDomain } from "../types";

export const AdminRoute = React.memo(function AdminRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { result } = useParseQuery(
    React.useMemo(
      () =>
        new Parse.Query(TenantDomain)
          .equalTo("tenant", _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant")]))
          .notContainedIn("domain", ["127.0.0.1", "localhost"]),
      []
    )
  );

  const [allGroups, allItems] = useNavigation("sidebar");

  const group = allGroups.find((group) => group.label === "app:admin.title");
  const items = allItems
    .filter((item) => item.group === _optionalChain([group, 'optionalAccess', _6 => _6.id]))
    .filter((item) => item.link)
    .filter((item) => item.icon)
    .map((item) => ({
      ...item,
      onClick: () => {
        if (item.link) {
          navigate(item.link);
        }
      },
    }));

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , React.createElement(ContainerInner, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
          , result.map((domain) => (
            React.createElement(Element, {
              key: domain.id,
              label: domain.get("domain"),
              icon: "fa:browser",
              color: "#1890ff",
              onClick: () => {
                _optionalChain([window
, 'optionalAccess', _7 => _7.open, 'call', _8 => _8("https://" + domain.get("domain"), "_blank")
, 'optionalAccess', _9 => _9.focus, 'call', _10 => _10()]);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            )
          ))

          , items.map((e) => (
            React.createElement(Element, { key: e.id, ...e, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
          ))

          , items.length === 0 && t("opendash:frontpage.empty")
        )
      )
    )
  );
});
