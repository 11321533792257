const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/InvoiceEditAdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";

import { useTranslation, AdminLayout } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import {
  createObject,
  deleteObject,
  updateObject,
} from "@opendash/plugin-parse-admin";
import {
  Button,
  List,
  Modal,
  Checkbox,
  Tooltip,
  message,
  Space,
  Divider,
} from "antd";

import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@opendash/icons";
import { useParseGet, useParseQuery } from "parse-hooks";
import { Invoice, InvoicePage } from "../types";
import { InvoicePagePDFImport } from "./InvoicePagePDFImport";
import { InvoicePageAnnotation } from "./InvoicePageAnnotation";
import { ParseObjectOrderButtons } from "./ParseObjectOrderButtons";

export const InvoiceEditAdminRoute = React.memo(
  function InvoiceEditAdminRoute() {
    const t = useTranslation();
    const navigate = useNavigate();
    const { invoiceId } = useParams();

    const [isImportVisible, setImportVisible] = React.useState(false);
    const [pageForAnnotation, setPageForAnnotation] =
      React.useState(null);

    const invoiceRequest = useParseGet(Invoice, invoiceId );

    const [showDeleted, setShowDeleted] = React.useState(false);

    const pageQuery = React.useMemo(() => {
      const query = new Parse.Query(InvoicePage)
        .equalTo("invoice", _optionalChain([invoiceRequest, 'optionalAccess', _ => _.result]) )
        .descending("order")
        .limit(1000000);

      if (!showDeleted) {
        query.equalTo("deleted", false);
      }

      return query;
    }, [_optionalChain([invoiceRequest, 'optionalAccess', _2 => _2.result, 'optionalAccess', _3 => _3.id]), showDeleted]);

    const pages = useParseQuery(pageQuery);

    if (!invoiceRequest.result) {
      return null;
    }

    return (
      React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
        , React.createElement(AdminToolbar, {
          title: t("app:admin.invoice_pages.title"),
          description: t("app:admin.invoice_pages.description"),
          padding: false,
          onBackButton: () => {
            navigate("/admin/rechnungserklaerer/invoice");
          },
          actions: [
            React.createElement(Button, {
              key: "import",
              type: "primary",
              icon: React.createElement(Icon, { icon: "fa:file-pdf", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}} ),
              children: t("app:admin.invoice_page_pdf_import.action"),
              onClick: () => {
                setImportVisible(true);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
            ),
            React.createElement(Button, {
              key: "new",
              type: "primary",
              icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} ),
              children: t("app:admin.invoice_pages.action_new"),
              onClick: () => {
                const preset = new InvoicePage({
                  tenant: _optionalChain([Parse, 'access', _4 => _4.User, 'access', _5 => _5.current, 'call', _6 => _6(), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("tenant")]),
                  invoice: _optionalChain([invoiceRequest, 'optionalAccess', _9 => _9.result]) ,
                });

                createObject("APP_InvoicePage", preset).then((objectId) => {
                  pages.reload();
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
            ),
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
        , React.createElement(List, {
          loading: pages.loading,
          dataSource: pages.result,
          renderItem: (page) => {
            return (
              React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
                , React.createElement(React.Fragment, null
                  , React.createElement(List.Item.Meta, {
                    title: page.get("title"),
                    description: page.get("description"),
                    avatar: 
                      React.createElement(Tooltip, {
                        overlay: 
                          page.get("deleted")
                            ? t(
                                "app:admin.invoice_pages.visibillity_change_tooltip_deleted"
                              )
                            : page.get("draft")
                            ? t(
                                "app:admin.invoice_pages.visibillity_change_tooltip_draft"
                              )
                            : t(
                                "app:admin.invoice_pages.visibillity_change_tooltip_nodraft"
                              )
                        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}

                        , React.createElement(Icon, {
                          icon: 
                            page.get("deleted")
                              ? "fa:trash"
                              : page.get("draft")
                              ? "fa:eye-slash"
                              : "fa:eye"
                          ,
                          style: {
                            fontSize: 20,
                            cursor: "pointer",
                            color:
                              page.get("deleted") || page.get("draft")
                                ? "red"
                                : undefined,
                          },
                          onClick: () => {
                            if (page.get("deleted")) {
                            } else if (page.get("draft")) {
                              page.set("draft", false);

                              page
                                .save()
                                .then(() => {
                                  message.success(
                                    t(
                                      "app:admin.invoice_pages.visibillity_change_success"
                                    )
                                  );
                                  return pages.reload();
                                })
                                .catch(() => {
                                  message.error(
                                    t(
                                      "app:admin.invoice_pages.visibillity_change_error"
                                    )
                                  );
                                });
                            } else {
                              page.set("draft", true);
                              page
                                .save()
                                .then(() => {
                                  message.success(
                                    t(
                                      "app:admin.invoice_pages.visibillity_change_success"
                                    )
                                  );
                                  return pages.reload();
                                })
                                .catch(() => {
                                  message.error(
                                    t(
                                      "app:admin.invoice_pages.visibillity_change_error"
                                    )
                                  );
                                });
                            }
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
                        )
                      )
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
                  )

                  , React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
                    , !showDeleted && (
                      React.createElement(ParseObjectOrderButtons, {
                        objects: pages.result,
                        current: page,
                        onChange: () => {
                          pages.reload();
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}
                      )
                    )

                    , React.createElement('span', { style: { display: "inline-block", width: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}} )
                    , !showDeleted && (
                      React.createElement(Button, {
                        key: "edit",
                        type: "primary",
                        icon: React.createElement(Icon, { icon: "fa:edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}} ),
                        children: t("app:admin.invoice_pages.action_edit"),
                        onClick: () => {
                          updateObject(page.className, page.id).then(
                            (saved) => {
                              if (saved) {
                                pages.reload();
                              }
                            }
                          );
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
                      )
                    )

                    , !showDeleted && (
                      React.createElement(Button, {
                        key: "edit-annotation",
                        type: "primary",
                        icon: React.createElement(Icon, { icon: "fa:marker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 224}} ),
                        children: t(
                          "app:admin.invoice_pages.action_annotation"
                        ),
                        onClick: () => {
                          setPageForAnnotation(page);
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
                      )
                    )

                    , page.get("deleted") && (
                      React.createElement(Button, {
                        key: "delete-restore",
                        type: "default",
                        icon: React.createElement(Icon, { icon: "fa:trash-undo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}} ),
                        children: t("app:admin.invoice_pages.action_restore"),
                        onClick: () => {
                          page.set("deleted", false);
                          page.set("draft", true);
                          page.set("order", 100);

                          page.save().then(
                            () => {
                              message.success(
                                t("app:admin.invoice_pages.restore_success")
                              );

                              pages.reload();
                            },
                            (error) => {
                              message.error(
                                t("app:admin.invoice_pages.restore_error")
                              );
                            }
                          );
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 235}}
                      )
                    )

                    , page.get("deleted") && (
                      React.createElement(Button, {
                        key: "delete-final",
                        type: "default",
                        danger: true,
                        icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 268}} ),
                        children: t(
                          "app:admin.invoice_pages.action_delete_final"
                        ),
                        onClick: () => {
                          deleteObject(page.className, page.id).then(() => {
                            pages.reload();
                          });
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 264}}
                      )
                    )

                    , !page.get("deleted") && (
                      React.createElement(Button, {
                        key: "delete",
                        type: "default",
                        danger: true,
                        icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}} ),
                        children: t("app:admin.invoice_pages.action_delete"),
                        onClick: () => {
                          Modal.confirm({
                            title: t(
                              "app:admin.invoice_pages.delete_confirm_title"
                            ),
                            content: t(
                              "app:admin.invoice_pages.delete_confirm_message"
                            ),
                            okText: t(
                              "app:admin.invoice_pages.delete_confirm_ok"
                            ),
                            okType: "danger",
                            cancelText: t(
                              "app:admin.invoice_pages.delete_confirm_cancel"
                            ),
                            onOk() {
                              page.set("deleted", true);
                              page.set("order", 0);

                              page.save().then(
                                () => {
                                  message.success(
                                    t("app:admin.invoice_pages.delete_success")
                                  );

                                  pages.reload();
                                },
                                (error) => {
                                  message.error(
                                    t("app:admin.invoice_pages.delete_error")
                                  );
                                }
                              );
                            },
                          });
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 281}}
                      )
                    )
                  )
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 332}} )

        , React.createElement(Checkbox, {
          checked: showDeleted,
          onChange: (e) => {
            setShowDeleted(e.target.checked);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 334}}

          , t("app:admin.invoice_pages.action_show_deleted")
        )

        , React.createElement(InvoicePagePDFImport, {
          visible: isImportVisible,
          onClose: () => setImportVisible(false),
          invoice: invoiceRequest.result ,
          onChange: (pageId) => {
            pages.reload();
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 343}}
        )

        , React.createElement(InvoicePageAnnotation, {
          page: pageForAnnotation,
          onClose: () => setPageForAnnotation(null),
          invoice: invoiceRequest.result ,
          onChange: (pageId) => {
            pages.reload();
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 352}}
        )
      )
    );
  }
);
