// @ts-check

import "antd/dist/antd.min.css";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { ParsePlugin } from "@opendash/plugin-parse";
import { ParseAdminPlugin } from "@opendash/plugin-parse-admin";

import { translate } from "@opendash/i18n";
import { AdminRoute } from "./components/AdminRoute";
import { GlossaryAdminRoute } from "./components/GlossaryAdminRoute";
import { ImportReferenceRoute } from "./components/ImportReferenceRoute";
import { InvoiceAdminRoute } from "./components/InvoiceAdminRoute";
import { InvoiceEditAdminRoute } from "./components/InvoiceEditAdminRoute";
import { TenantConfigRoute } from "./components/TenantConfigRoute";
import { VideoAdminRoute } from "./components/VideoAdminRoute";
import { ParseAdminConfig } from "./parse.config";

init("opendash", async (factory) => {
  // Set document title
  try {
    window.document.title = "ASEW Rechnungserklärer Administration";
  } catch (error) {}

  // Logo:

  factory.ui.setLogoImage(require("../assets/logo.png"));

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/asew-default.json"));

  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);

  factory.registerTranslationResolver(
    "en",
    "app",
    () => import("./translations/en/app.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    () => import("./translations/en/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerTranslationResolver(
    "de",
    "app",
    () => import("./translations/de/app.json")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    () => import("./translations/de/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new ParsePlugin({ useLiveQueries: false }));
  await factory.use(new ParseAdminPlugin(ParseAdminConfig));

  // Routes

  factory.registerRoute({
    path: "/admin/rechnungserklaerer",
    component: async () => ({ default: AdminRoute }),
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/import",
    component: async () => ({ default: ImportReferenceRoute }),
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/config-appearance",
    component: async () => ({ default: TenantConfigRoute }),
    props: {
      type: "appearance",
    },
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/config-navigation",
    component: async () => ({ default: TenantConfigRoute }),
    props: {
      type: "navigation",
    },
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/config-contact",
    component: async () => ({ default: TenantConfigRoute }),
    props: {
      type: "contact",
    },
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/invoice",
    component: async () => ({ default: InvoiceAdminRoute }),
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/invoice/:invoiceId",
    component: async () => ({ default: InvoiceEditAdminRoute }),
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/glossary",
    component: async () => ({ default: GlossaryAdminRoute }),
  });

  factory.registerRoute({
    path: "/admin/rechnungserklaerer/video",
    component: async () => ({ default: VideoAdminRoute }),
  });
}).then(async (app) => {
  // TODO: workaround to preload translation file
  await translate("parse-admin:admin.delete.action");
});
