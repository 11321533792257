const _jsxFileName = "/home/runner/work/asew-rechnungserklaerer-admin/asew-rechnungserklaerer-admin/src/js/components/GlossaryAdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";

import { useTranslation, AdminLayout, useForceRender } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import {
  createObject,
  deleteObject,
  updateObject,
} from "@opendash/plugin-parse-admin";
import { Button, Table } from "antd";


import { useParseQuery } from "parse-hooks";
import { GlossaryEntry, Language } from "../types";
import { Icon } from "@opendash/icons";

import { DEFAULT_TENANT_ID } from "../const";

export const GlossaryAdminRoute = React.memo(function GlossaryAdminRoute() {
  const t = useTranslation();
  const forceRender = useForceRender();
  const [search, onSearch] = React.useState("");

  const currentTenant = _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant")]);

  const entriesQuery = React.useMemo(
    () =>
      new Parse.Query(GlossaryEntry)
        .equalTo("tenant", currentTenant)
        .ascending("keyword")
        .limit(1000000),
    [_optionalChain([currentTenant, 'optionalAccess', _6 => _6.id])]
  );

  const defaultEntriesQuery = React.useMemo(
    () =>
      new Parse.Query(GlossaryEntry)
        .equalTo(
          "tenant",
          new Parse.Object("OD3_Tenant", { id: DEFAULT_TENANT_ID })
        )
        // .ascending("title")
        .limit(1000000),
    []
  );

  const entries = useParseQuery(entriesQuery);
  const defaultEntries = useParseQuery(defaultEntriesQuery);

  const { rows, languages } = React.useMemo(() => {
    const map = {};
    const languages = [];

    const all = [...defaultEntries.result, ...entries.result];

    for (const e of all) {
      const lang = _optionalChain([e, 'access', _7 => _7.get, 'call', _8 => _8("language"), 'optionalAccess', _9 => _9.id]);
      const keyword = e.get("keyword");

      if (!map[keyword]) {
        map[keyword] = {};
      }

      if (!languages.includes(lang)) {
        languages.push(lang);
      }

      map[keyword][lang] = e;
    }

    const rows = Object.entries(map).map(([keyword, translations]) => {
      return {
        keyword,
        translations,
      };
    });

    return {
      languages,
      rows,
    };
  }, [defaultEntries.result, entries.result]);

  const cols = React.useMemo(() => {
    


    const cols = [
      // {
      //   title: t("app:admin.glossary.col_keyword"),
      //   key: "keyword",
      //   render: (value, row) => {
      //     return row.keyword;
      //   },
      // },

      ...languages.map((language, i) => {
        const col = {
          title: language,
          key: `language~${language}`,
          dataIndex: language,
          width: 100 / languages.length + "%",

          sorter: (a, b) => {
            const titleA = _optionalChain([a, 'access', _10 => _10.translations, 'access', _11 => _11[language], 'optionalAccess', _12 => _12.get, 'call', _13 => _13("title")]) || "";
            const titleB = _optionalChain([b, 'access', _14 => _14.translations, 'access', _15 => _15[language], 'optionalAccess', _16 => _16.get, 'call', _17 => _17("title")]) || "";

            return titleA.localeCompare(titleB, language);
          },

          sortDirections: ["ascend", "descend", "ascend"],

          ...(i === 0 && {
            defaultSortOrder: "ascend",
          }),

          render: (value, row) => {
            const translation = row.translations[language];

            if (
              translation &&
              _optionalChain([translation, 'access', _18 => _18.get, 'call', _19 => _19("tenant"), 'optionalAccess', _20 => _20.id]) !==
                _optionalChain([Parse, 'access', _21 => _21.User, 'access', _22 => _22.current, 'call', _23 => _23(), 'optionalAccess', _24 => _24.get, 'call', _25 => _25("tenant"), 'optionalAccess', _26 => _26.id])
            ) {
              return (
                React.createElement(TableCell, {
                  title: translation.get("title"),
                  actionLabel: t("app:admin.glossary.action_overwrite"),
                  actionIcon: "fa:edit",
                  actionHandler: () => {
                    const entry = new GlossaryEntry({
                      keyword: row.keyword,
                      language: new Language({ id: language }),
                      title: translation.get("title"),
                      text: translation.get("text"),
                    });

                    createObject(entry.className, entry, [
                      "title",
                      "text",
                    ]).then((saved) => {
                      if (saved) {
                        entries.reload();
                      }
                    });
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
                )
              );
            }

            if (translation) {
              return (
                React.createElement(TableCell, {
                  title: translation.get("title"),
                  actionLabel: t("app:admin.glossary.action_edit"),
                  actionIcon: "fa:edit",
                  actionHandler: () => {
                    updateObject(translation.className, translation.id, [
                      "title",
                      "text",
                    ]).then((saved) => {
                      if (saved) {
                        entries.reload();
                      }
                    });
                  },
                  deleteLabel: t("app:admin.glossary.action_delete"),
                  deleteHandler: () => {
                    deleteObject(translation.className, translation.id).then(
                      (deleted) => {
                        if (deleted) {
                          Promise.resolve(entries.reload()).then(() => {
                            forceRender();
                          });
                        }
                      }
                    );
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
                )
              );
            }

            return (
              React.createElement(TableCell, {
                warning: true,
                title: t("app:admin.glossary.cell_empty"),
                actionLabel: t("app:admin.glossary.action_create"),
                actionIcon: "fa:plus",
                actionPrimary: true,
                actionHandler: () => {
                  const entry = new GlossaryEntry({
                    keyword: row.keyword,
                    language: new Language({ id: language }),
                  });
                  createObject(entry.className, entry, ["title", "text"]).then(
                    (saved) => {
                      if (saved) {
                        entries.reload();
                      }
                    }
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 185}}
              )
            );
          },
        };

        return col;
      }),
    ];

    return cols;
  }, [languages]);

  const rowsAfterSearch = React.useMemo(() => {
    if (!search) {
      return rows;
    }

    const s = search.toLocaleLowerCase();

    return rows.filter((row) => {
      for (const translation of Object.values(row.translations)) {
        if (_optionalChain([translation, 'access', _27 => _27.get, 'call', _28 => _28("title"), 'optionalAccess', _29 => _29.toLocaleLowerCase, 'call', _30 => _30(), 'access', _31 => _31.includes, 'call', _32 => _32(s)])) {
          return true;
        }

        // if (translation.get("text")?.toLocaleLowerCase().includes(s)) {
        //   return true;
        // }
      }

      return false;
    });
  }, [rows, search]);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 239}}
      , React.createElement(AdminToolbar, {
        title: t("app:admin.glossary.title"),
        description: t("app:admin.glossary.description"),
        padding: false,
        search: search,
        onSearch: onSearch,
        onBackButton: 
          search
            ? () => {
                onSearch("");
              }
            : undefined
        ,
        actions: [
          React.createElement(Button, {
            key: "new",
            type: "primary",
            icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 257}} ),
            children: t("app:admin.glossary.action_new"),
            onClick: async () => {
              try {
                const id = await createObject("APP_GlossaryEntry");
                await entries.reload();
                const entry = await new Parse.Query("APP_GlossaryEntry").get(
                  id
                );

                if (entry) {
                  onSearch(entry.get("title"));
                }
              } catch (error) {}
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}
          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 240}}
      )

      , cols.length > 0 && (
        React.createElement(Table, {
          rowKey: "id",
          loading: entries.loading && defaultEntries.loading,
          dataSource: rowsAfterSearch,
          columns: cols,
          pagination: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 277}}
        )
      )

      /* <List
        loading={entries.loading}
        dataSource={entries.result}
        renderItem={(entry) => {
          return (
            <List.Item>
              <>
                <List.Item.Meta
                  title={
                    <span>
                      {entry.get("keyword")}{" "}
                      <Tag>{entry.get("language")?.id}</Tag>
                    </span>
                  }
                  description={entry.get("title")}
                />

                <Space direction="horizontal">
                  <Button
                    key="edit"
                    type="primary"
                    icon={<Icon icon="fa:edit" />}
                    children={t("app:admin.glossary.action_edit")}
                    onClick={() => {
                      updateObject(entry.className, entry.id).then((saved) => {
                        if (saved) {
                          entries.reload();
                        }
                      });
                    }}
                  />

                  <Button
                    key="delete"
                    type="default"
                    danger
                    icon={<Icon icon="fa:trash" />}
                    children={t("app:admin.glossary.action_delete")}
                    onClick={() => {
                      deleteObject(entry.className, entry.id).then(
                        (deleted) => {
                          if (deleted) {
                            entries.reload();
                          }
                        }
                      );
                    }}
                  />
                </Space>
              </>
            </List.Item>
          );
        }}
      /> */
    )
  );
});












function TableCell({
  title,
  actionLabel,
  actionIcon,
  actionHandler,
  actionPrimary = false,
  warning = false,
  deleteLabel,
  deleteHandler,
}) {
  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 366}}
      , React.createElement('span', {
        style: {
          display: "block",
          marginBottom: 10,
          paddingBottom: 3,
          borderBottom: "1px solid rgba(0,0,0,.06)",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 367}}

        , warning && (
          React.createElement(Icon, { icon: "fa:exclamation-triangle", style: { marginRight: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 376}} )
        )
        , title
      )
      , React.createElement(Button, {
        size: "small",
        type: actionPrimary ? "primary" : "default",
        icon: React.createElement(Icon, { icon: actionIcon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 383}} ),
        children: actionLabel,
        onClick: actionHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 380}}
      )

      , deleteLabel && deleteHandler && (
        React.createElement(Button, {
          style: { marginLeft: 10 },
          size: "small",
          type: "default",
          danger: true,
          icon: React.createElement(Icon, { icon: "fa:trash-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 394}} ),
          children: deleteLabel,
          onClick: deleteHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 389}}
        )
      )
    )
  );
}
