import Parse from "parse";









export class Language extends Parse.Object {
  constructor(data) {
    super("APP_Language", data );
  }
}

Parse.Object.registerSubclass("APP_Language", Language);
