import Parse from "parse";






















export class InvoicePage extends Parse.Object {
  constructor(data) {
    super("APP_InvoicePage", data );
  }
}

Parse.Object.registerSubclass("APP_InvoicePage", InvoicePage);
